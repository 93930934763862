<template>
    <div class="">
<div class="row">
<div class="service-type-heading  col-12" v-if="false">All Common Services</div>
<div class="service-type  col-12"><span>Pickup</span></div>
<div class="col-12 col-md-4" v-for="(service) in pickupServices" :key="service.code">
        <div class="" >
    <div class="">
        <b-form-checkbox
            v-model="selectedServices"
            :value="{code: service.code, attributes: service.attributes}"
            class="checkbox" size="lg"><span class="d4w-label">{{ service.name }}</span></b-form-checkbox>
    </div>
    <div class="" v-if="isSelected(service) && service.attributes">
        <div class="mt-2" v-for="(value, key) in service.attributes" :key="key">
            <label :for="key" class="d4w-label  text-capitalize px-2">{{ key.replaceAll('_',' ') }}:</label>
            <div class="">
                <input class="input-attribute mx-2"  :style="[{borderBottom:'1px solid '+pro.style.color}]" type="text"  v-model="service.attributes[key]" :id="key">
            </div>
        </div>
    </div>
    </div>
</div>
<div class="service-type mt-3 col-12"><span>Delivery</span></div>
    <div class="col-12 col-md-4"  v-for="(service) in deliveryServices" :key="service.code">
        <div class="">
    <div class=""  >
        <b-form-checkbox
            v-model="selectedServices"
            :value="{code: service.code, attributes: service.attributes}"
            class="checkbox" size="lg"><span class="d4w-label">{{ service.name }}</span> </b-form-checkbox>
    </div>
    <div class="" v-if="isSelected(service) && service.attributes">
        <div class="mt-2" v-for="(value, key) in service.attributes" :key="key">
            <label :for="key" class="d4w-label  text-capitalize px-2">{{ key.replaceAll('_',' ') }}:</label>
            <div class="">
                <input class="input-attribute mx-2"  :style="[{borderBottom:'1px solid '+pro.style.color}]" type="text"  v-model="service.attributes[key]" :id="key">
            </div>
        </div>
    </div>
</div>
</div>
<div class="service-type mt-3 col-12"><span>Order</span></div>
<div class="col-12"  v-for="(service,orderServiceIndex) in orderServices" :key="orderServiceIndex" :class="[orderServiceIndex==1?'col-md-6':'col-md-4']">
        <div class=""  >
    <div class="">
        <b-form-checkbox
            v-model="selectedServices"
            :value="{code: service.code, attributes: service.attributes}"
            class="checkbox" size="lg"><span class="d4w-label">{{ service.name }}</span></b-form-checkbox>
    </div>
    <div class="" v-if="isSelected(service) && service.attributes">
        <div class="mt-2" v-for="(value, key) in service.attributes" :key="key">
            <label :for="key" class="d4w-label  text-capitalize px-2">{{ key.replaceAll('_',' ') }}:</label>
            <div class="">
                <input class="input-attribute mx-2"  :style="[{borderBottom:'1px solid '+pro.style.color}]" type="text"  v-model="service.attributes[key]" :id="key">
            </div>
        </div>
    </div>
</div>
</div>
</div>
<div class="row" v-if="false">
<div class="service-type-heading  col-12 mt-3">Additional Services</div>
<div class="service-type  col-12"><span>Saia Services</span></div>
<div class="col-12"  v-for="(service,orderServiceIndex) in saiaServices" :key="orderServiceIndex" :class="[orderServiceIndex==1?'col-md-6':'col-md-4']">
        <div class=""  >
    <div class="">
        <b-form-checkbox
            v-model="selectedServices"
            :value="{code: service.code, attributes: service.attributes}"
            class="checkbox" size="lg"><span class="d4w-label">{{ service.name }}</span></b-form-checkbox>
    </div>
    <div class="" v-if="isSelected(service) && service.attributes">
        <div class="mt-2" v-for="(value, key) in service.attributes" :key="key">
            <label :for="key" class="d4w-label  text-capitalize px-2">{{ key.replaceAll('_',' ') }}:</label>
            <div class="">
                <input class="input-attribute mx-2"  :style="[{borderBottom:'1px solid '+pro.style.color}]" type="text"  v-model="service.attributes[key]" :id="key">
            </div>
        </div>
    </div>
</div>
</div>

<div class="service-type  col-12"><span>ODFL Services</span></div>
<div class="col-12"  v-for="(service,orderServiceIndex) in odflServices" :key="orderServiceIndex" :class="[orderServiceIndex==1?'col-md-6':'col-md-4']">
        <div class=""  >
    <div class="">
        <b-form-checkbox
            v-model="selectedServices"
            :value="{code: service.code, attributes: service.attributes}"
            class="checkbox" size="lg"><span class="d4w-label">{{ service.name }}</span></b-form-checkbox>
    </div>
    <div class="" v-if="isSelected(service) && service.attributes">
        <div class="mt-2" v-for="(value, key) in service.attributes" :key="key">
            <label :for="key" class="d4w-label  text-capitalize px-2">{{ key.replaceAll('_',' ') }}:</label>
            <div class="">
                <input class="input-attribute mx-2"  :style="[{borderBottom:'1px solid '+pro.style.color}]" type="text"  v-model="service.attributes[key]" :id="key">
            </div>
        </div>
    </div>
</div>
</div>


<div class="service-type  col-12"><span>ABFS Services</span></div>
<div class="col-12"  v-for="(service,orderServiceIndex) in abfsServices" :key="orderServiceIndex" :class="[orderServiceIndex==1?'col-md-6':'col-md-4']">
        <div class=""  >
    <div class="">
        <b-form-checkbox
            v-model="selectedServices"
            :value="{code: service.code, attributes: service.attributes}"
            class="checkbox" size="lg"><span class="d4w-label">{{ service.name }}</span></b-form-checkbox>
    </div>
    <div class="" v-if="isSelected(service) && service.attributes">
        <div class="mt-2" v-for="(value, key) in service.attributes" :key="key">
            <label :for="key" class="d4w-label  text-capitalize px-2">{{ key.replaceAll('_',' ') }}:</label>
            <div class="">
                <input class="input-attribute mx-2"  :style="[{borderBottom:'1px solid '+pro.style.color}]" type="text"  v-model="service.attributes[key]" :id="key">
            </div>
        </div>
    </div>
</div>
</div>
</div>
</div>
</template>
  <script>
  export default{
    props:{
        pro:Object,
        selected:Array
    },

    methods:{
        isSelected(service) {
        return this.selectedServices.some(s => 
            s.code === service.code && JSON.stringify(s.attributes) === JSON.stringify(service.attributes)
        );
    }
    },
    data(){
      return {
        selectedServices:[...this.selected],
    deliveryServices : [
   
    {
        "attributes": null,
        "code": "cnstd",
        "features": [
            "quote"
        ],
        "name": "Construction site delivery"
    },
    {
        "attributes": null,
        "code": "lftd",
        "features": [
            "quote"
        ],
        "name": "Lift gate required at delivery"
    },
    {
        "attributes": null,
        "code": "ltdad",
        "features": [
            "quote"
        ],
        "name": "Limited access delivery"
    },
    {
        "attributes": null,
        "code": "mnc",
        "features": [
            "quote"
        ],
        "name": "Must notify consignee"
    },
   
    {
        "attributes": null,
        "code": "psc",
        "features": [
            "quote"
        ],
        "name": "Protective from cold"
    },
    {
        "attributes": null,
        "code": "res",
        "features": [
            "quote"
        ],
        "name": "Residential delivery"
    },
    {
        "attributes": null,
        "code": "aptd",
        "features": [
            "quote"
        ],
        "name": "Appointment required at delivery"
    },
    {
        "attributes": null,
        "code": "ovr",
        "features": [
            "quote"
        ],
        "name": "Over dimension/excessive length"
    },
],

// Pickup services array
pickupServices : [
    {
        "attributes": null,
        "code": "cnstp",
        "features": [
            "quote"
        ],
        "name": "Construction site pickup"
    },
    {
        "attributes": null,
        "code": "ipu",
        "features": [
            "quote"
        ],
        "name": "Inside pickup"
    },
    {
        "attributes": null,
        "code": "lftp",
        "features": [
            "quote"
        ],
        "name": "Lift gate required at pickup"
    },
    {
        "attributes": null,
        "code": "ltdap",
        "features": [
            "quote"
        ],
        "name": "Limited access pickup"
    },
    {
        "attributes": null,
        "code": "rep",
        "features": [
            "quote"
        ],
        "name": "Residential pickup"
    }
],

// Order services array
orderServices :[
    {
        "attributes": {
            "name": '',
            "phone": ''
        },
        "code": "haz",
        "features": [
            "quote"
        ],
        "name": "Hazardous material"
    },
    {
        "attributes": {
            "additional_insurance_amount": '',
            "monetary_value": ''
        },
        "code": "ins",
        "features": [
            "quote"
        ],
        "name": "Additional insurance/excess value"
    }
    // Add more order-related services here if needed
],
        services:[
        {
            "attributes": null,
            "code": "aptd",
            "features": [
                "quote"
            ],
            "name": "Appointment required at delivery"
        },
        {
            "attributes": null,
            "code": "cnstd",
            "features": [
                "quote"
            ],
            "name": "Construction site delivery"
        },
        {
            "attributes": null,
            "code": "cnstp",
            "features": [
                "quote"
            ],
            "name": "Construction site pickup"
        },
        {
            "attributes": {
                "name": '',
                "phone": ''
            },
            "code": "haz",
            "features": [
                "quote"
            ],
            "name": "Hazardous material"
        },
        {
            "attributes": null,
            "code": "idl",
            "features": [
                "quote"
            ],
            "name": "Inside delivery"
        },
        {
            "attributes": {
                "additional_insurance_amount": '',
                "monetary_value": ''
            },
            "code": "ins",
            "features": [
                "quote"
            ],
            "name": "Additional insurance/excess value"
        },
        {
            "attributes": null,
            "code": "ipu",
            "features": [
                "quote"
            ],
            "name": "Inside pickup"
        },
        {
            "attributes": null,
            "code": "lftd",
            "features": [
                "quote"
            ],
            "name": "Lift gate required at delivery"
        },
        {
            "attributes": null,
            "code": "lftp",
            "features": [
                "quote"
            ],
            "name": "Lift gate required at pickup"
        },
        {
            "attributes": null,
            "code": "ltdad",
            "features": [
                "quote"
            ],
            "name": "Limited access delivery"
        },
        {
            "attributes": null,
            "code": "ltdap",
            "features": [
                "quote"
            ],
            "name": "Limited access pickup"
        },
        {
            "attributes": null,
            "code": "mnc",
            "features": [
                "quote"
            ],
            "name": "Must notify consignee"
        },
        {
            "attributes": null,
            "code": "ovr",
            "features": [
                "quote"
            ],
            "name": "Over dimension/excessive length"
        },
        {
            "attributes": null,
            "code": "psc",
            "features": [
                "quote"
            ],
            "name": "Protective from cold"
        },
        {
            "attributes": null,
            "code": "rep",
            "features": [
                "quote"
            ],
            "name": "Residential pickup"
        },
        {
            "attributes": null,
            "code": "res",
            "features": [
                "quote"
            ],
            "name": "Residential delivery"
        }
    ],
    saiaServices:[
    {
        "attributes": null,
        "code": "aptp",
        "features": [
            "spot_quote"
        ],
        "name": "Appointment required at pickup"
    },
    {
        "attributes": null,
        "code": "ebd",
        "features": [
            "quote"
        ],
        "name": "Tradeshow delivery"
    },
    {
        "attributes": null,
        "code": "tcs",
        "features": [],
        "name": "Time Critical Service"
    }
],
odflServices:[
    {
        "attributes": null,
        "code": "aptp",
        "features": [
            "spot_quote"
        ],
        "name": "Appointment required at pickup"
    },
    {
        "attributes": null,
        "code": "ebd",
        "features": [
            "quote"
        ],
        "name": "Tradeshow delivery"
    },
    {
        "attributes": null,
        "code": "tcs",
        "features": [],
        "name": "Time Critical Service"
    }
],
abfsServices:[
    {
        "attributes": {
            "amount": {}
        },
        "code": "cod",
        "features": [
            "quote"
        ],
        "name": "Collect on delivery"
    },
    {
        "attributes": null,
        "code": "ebp",
        "features": [
            "quote",
            "spot_quote"
        ],
        "name": "Tradeshow pickup"
    },
    {
        "attributes": null,
        "code": "inbd",
        "features": [
            "quote",
            "spot_quote"
        ],
        "name": "In bond shipment"
    },
    {
        "attributes": {
            "count": {}
        },
        "code": "mark",
        "features": [
            "quote"
        ],
        "name": "Marked or tagged"
    },
    {
        "attributes": {
            "pieces": {}
        },
        "code": "srt",
        "features": [
            "quote"
        ],
        "name": "SRT - Sort and segregate"
    },
    {
        "attributes": null,
        "code": "ss",
        "features": [
            "quote"
        ],
        "name": "SS - Single Shipment"
    }
]

      }
    },
    watch:{
        alreadySelected:{
            handler(newValue){
                this.selectedServices=newValue
               
            },
            deep:true
        },
        selectedServices:{
            handler(newValue){
                if(this.isValidServices){
                    this.$emit('change',newValue)
                }
                else{
                    this.$emit('change',[])
                }
            },
            deep:true
        }
    },
    computed:{
        alreadySelected(){
            return this.selected
        },
        isValidServices() {
            let checkValidity= true
      for (const service of this.selectedServices) {
        if (service.attributes != null) {
          for (const key in service.attributes) {
            if (!service.attributes[key]) {
                checkValidity= false;
            }
        }
        
        }
      }
      return checkValidity;
    }
    },
    mounted(){
        this.selectedServices=[...this.selected]
    }
  }
  </script>
  <style scoped>
  
  .checkbox >>> .custom-control-input:checked ~ .custom-control-label::before {
    color: #fff;
    border-color: #303030;
    background-color: #303030 !important;
  }
  .checkbox >>> .custom-control-input:focus ~ .custom-control-label::before {
    box-shadow: 0 0 0 0.2rem #303030;
  }
  .service-type span{
    text-wrap: nowrap;
    padding-right: 5px;
  }
  .service-type{
    font-size: 14px;
font-style: normal;
font-weight: 600;
line-height: 20px;
    margin-bottom: 5px;
    display: flex;
    align-items: center;
    justify-content: center;

  }
  .service-type::after{
    content:'';
    display: block;
    width: 100%;
    height: 1px;
    background-color: #E6E5E5;
  }
  .service-type-heading{
      font-size: 18px;
    font-weight: bold;
    margin-bottom: 5px;

  }
  .label {
  margin: 0px;
  color: #606060;
  font-size: 15px;
  font-weight: 400;
  text-align: center;
}
.input-attribute{
    border:none
}
.input-attribute:focus-visible{
outline:none;
}
.d4w-label {
  margin-bottom: 4px;
  color: #303030;
  font-size: 13px;
  font-weight: 450;
  text-align: center;
  line-height: 20px;
}

  </style>
  